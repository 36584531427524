import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { HTTP_STATUS_CODES } from '@bbraun/cortex/shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError } from 'rxjs';

export const unauthorizedErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const oAuthService = inject(OAuthService);

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        oAuthService.logOut();
      }
      throw error;
    })
  );
};
