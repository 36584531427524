@if (layoutStoreFacade.showSplashScreen$ | async) {
  <cx-splash-screen
    #splashScreen
    [appName]="APP_NAME"
    (animationDone)="layoutStoreFacade.removeSplashScreen()"/>
}

<cx-header *transloco="let t" layoutClass="mx-m">
  @if (headerLayout.hasBackNavigation) {
    <cx-header-back-navigator (click)="navigateBack(headerLayout.backNavigationRoute)"/>
  }
  <cx-header-title>
    <div class="d-flex align-items-center">
      <mat-icon class="mr-xs d-flex align-items-center justify-content-center large-icon" svgIcon="app_icon" />
      {{ APP_NAME }}
    </div>
  </cx-header-title>
  <cx-header-quickbar>
    @if (userConfigsFacade.isSuperAdminAsSignal()) {
      <button
        class="align-self-center mr-s"
        mat-icon-button
        (click)="onSwitchButtonClicked()"
        [matTooltip]="t(userConfigsFacade.isInternalAsSignal() ? 'Switch to External view' : 'Switch to Internal view')">
        <mat-icon svgIcon="swap_horiz"/>
      </button>
    }
    <app-header-profile />
  </cx-header-quickbar>
</cx-header>

@if (noAccessReason) {
  <app-no-permission [reason]="noAccessReason"/>
}

@if (navigationStarted) {
  @if ((layoutStoreFacade.hideMainNavigationDrawer$ | async) === false) {
    <div>
      @if ((userConfigsFacade.isExternal$ | async)) {
        <app-navigation-external/>
      }
      @if ((userConfigsFacade.isInternal$ | async)) {
        <app-navigation-internal/>
      }
    </div>
  }
  @if (layoutStoreFacade.uiConfigsLoaded$ | async) {
    <cx-navigation-drawer-container class="main-navigation-drawer-container">
      <router-outlet/>
    </cx-navigation-drawer-container>
  }
}

@if (sidebarDisplayStore.countrySwitchSidebarDisplayed()) {
  <app-country-switch-sidebar/>
}
@if (sidebarDisplayStore.switchViewSidebarDisplayed()) {
  <app-switch-view-sidebar/>
}
