import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { CxNavigationDrawerModule } from '@bbraun/cortex/navigation-drawer';
import { TranslocoDirective } from '@jsverse/transloco';

import { ROUTES } from '../../shared/constants/routes.constants';
import { processTablesNames } from '../../shared/enums/process-tables/process-tables-names.enum';
import { ProcessStatuses } from '../../shared/enums/replenishment-process/process-status';
import { ProcessType } from '../../shared/enums/replenishment-process/process-type.enum';
import { LayoutStoreFacade } from '../../store/layout/layout-store.facade';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';
import { NavigationCountrySwitchComponent } from '../navigation-country-switch/navigation-country-switch.component';

@Component({
  selector: 'app-navigation-external',
  standalone: true,
  imports: [
    CommonModule,
    CxNavigationDrawerModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    TranslocoDirective,
    RouterLink,
    NavigationCountrySwitchComponent
  ],
  templateUrl: './navigation-external.component.html',
  styleUrls: [ './navigation-external.component.sass' ]
})
export class NavigationExternalComponent {
  protected readonly ROUTES = ROUTES;
  protected readonly ProcessType = ProcessType;
  protected readonly processStatuses = ProcessStatuses;

  constructor(
    protected readonly userConfigsFacade: UserConfigsStoreFacade,
    protected readonly layoutFacade: LayoutStoreFacade,
  ) { }

  protected readonly LayoutStoreFacade = LayoutStoreFacade;
  protected readonly processTablesNames = processTablesNames;
}
