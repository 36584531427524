@if (userConfigsFacade.user$ | async; as user) {
  <cx-profile-dropdown
    [firstname]="user.name"
    [lastname]="user.surname"
    [email]="user.email"
    [logoutText]="'Log Out' | transloco"
    [profileText]="'Profile Settings' | transloco"
    (logout)="onLogout()"
    (goToProfile)="onProfile()" />
}
