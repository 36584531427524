@if (userConfigsFacade.userAsSignal()) {
  <button mat-icon-button
          #notificationMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="notificationMenu"
          (menuOpened)="onMenuChange(true)"
          (menuClosed)="onMenuChange(false)">
    <mat-icon [svgIcon]="isNotificationDropdownOpen() ? 'notification_menu_opened': 'notification_menu_closed'"
              matBadgeColor="accent"
              [matBadge]="layoutFacade.unreadNotificationsCountForIconSignal()"
              [matBadgeHidden]="layoutFacade.unreadNotificationsCountSignal() < 1 "/>
  </button>
}

<mat-menu xPosition="before" #notificationMenu="matMenu" class="notification-menu-container">
  <div class="d-flex flex-column notification-menu" *transloco="let t" (click)="$event.stopPropagation()">
    <div class="notification-header d-flex align-items-center pb-m">
      <span class="notification-menu-header ml-xs">{{ t('Notifications') }}</span>
      @if (notificationsState.hasNotifications()) {
        <span class="small-link ml-auto" (click)="onMarkAllAsReadClicked()">{{ t('Mark all as read') }}</span>
      }
    </div>
    <mat-divider/>
    <div class="notifications-list py-2xs">
      @if (notificationsState.hasNotifications()) {
        @for (notification of notificationsState.entities(); track notification.id; let isLast = $last) {
          <app-notification-row class="d-block" [class.mb-m]="!isLast" [notificationData]="notification" [compactMode]="true"/>
        }
      } @else if (notificationsState.isLoading()) {
        <cx-heart-beat width="250px" [loading]="true"/>
      } @else {
        <div class="empty-notifications-container d-flex flex-column justify-content-center align-items-center">
          <mat-icon class="mb-m" svgIcon="no_results_icon"/>
          <span class="font-weight-bold">{{ t('No notifications yet') }}</span>
          <span class="font-s">{{ t('You will see notifications here when they are available.') }}</span>
        </div>
      }
    </div>
    @if (notificationsState.hasNotifications()) {
      <mat-divider/>
      <div class="notification-footer pt-m">
        <span class="small-link pt-m" (click)="onViewAllNotificationsClick()">{{ t('View all notifications') }}</span>
      </div>
    }
  </div>
</mat-menu>
