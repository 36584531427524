export const customIconsList = [
  { name: 'call_black_24dp', path: 'assets/icons/contact-details/call_black_24dp.svg' },
  { name: 'contact_page_black_24dp', path: 'assets/icons/contact-details/contact_page_black_24dp.svg' },
  { name: 'email_black_24dp', path: 'assets/icons/contact-details/email_black_24dp.svg' },
  { name: 'all_inbox_black_24dp', path: 'assets/icons/navigation/all_inbox_black_24dp.svg' },
  { name: 'analytics_black_24dp', path: 'assets/icons/navigation/analytics_black_24dp.svg' },
  { name: 'home_black_24dp', path: 'assets/icons/navigation/home_black_24dp.svg' },
  { name: 'inventory_2_black_24dp', path: 'assets/icons/navigation/inventory_2_black_24dp.svg' },
  { name: 'label_black_24dp', path: 'assets/icons/navigation/label_black_24dp.svg' },
  { name: 'multiple_stop_black_24dp', path: 'assets/icons/navigation/multiple_stop_black_24dp.svg' },
  { name: 'pending_black_24dp', path: 'assets/icons/navigation/pending_black_24dp.svg' },
  { name: 'production_quantity_limits_black_24dp', path: 'assets/icons/navigation/production_quantity_limits_black_24dp.svg' },
  { name: 'schedule_send_black_24dp', path: 'assets/icons/navigation/schedule_send_black_24dp.svg' },
  { name: 'send_black_24dp', path: 'assets/icons/navigation/send_black_24dp.svg' },
  { name: 'send_back_black_24dp', path: 'assets/icons/navigation/send_back_black_24dp.svg' },
  { name: 'send_back_black_filled_24dp', path: 'assets/icons/navigation/send_back_black_filled_24dp.svg' },
  { name: 'summarize_black_24dp', path: 'assets/icons/navigation/summarize_black_24dp.svg' },
  { name: 'task_black_24dp', path: 'assets/icons/navigation/task_black_24dp.svg' },
  { name: 'local_offer_black_24dp', path: 'assets/icons/navigation/local_offer_black_24dp.svg' },
  { name: 'add_circle_outline_black_24dp', path: 'assets/icons/navigation/add_circle_outline_black_24dp.svg' },
  { name: 'playlist_add_black_24dp', path: 'assets/icons/navigation/playlist_add_black_24dp.svg' },
  { name: 'report_gmailerrorred_black_24dp', path: 'assets/icons/navigation/report_gmailerrorred_black_24dp.svg' },
  { name: 'thumb_down_alt_black_24dp', path: 'assets/icons/navigation/thumb_down_alt_black_24dp.svg' },
  { name: 'thumb_up_black_24dp', path: 'assets/icons/navigation/thumb_up_black_24dp.svg' },
  { name: 'shopping_cart_circle', path: 'assets/icons/table-icons/shopping_cart_circle.svg' },
  { name: 'custom_build_circle', path: 'assets/icons/table-icons/custom_build_circle.svg' },
  { name: 'filter_alt_off_outlined', path: 'assets/icons/table-icons/filter_alt_off_outlined.svg' },
  { name: 'repair_request_circle', path: 'assets/icons/table-icons/repair_request_circle.svg' },
  { name: 'repair_replacement_circle', path: 'assets/icons/table-icons/repair_replacement_circle.svg' },
  { name: 'summarize_outline_24dp', path: 'assets/icons/navigation/summarize_outline_24dp.svg' },
  { name: 'ballot_outline_24dp', path: 'assets/icons/navigation/ballot_outline_24dp.svg' },
  { name: 'ballot_black_24dp', path: 'assets/icons/navigation/ballot_black_24dp.svg' },
  { name: 'ballot_active_24dp', path: 'assets/icons/navigation/ballot_active_24dp.svg' },
  { name: 'send_black_active_24dp', path: 'assets/icons/navigation/send_black_active_24dp.svg' },
  { name: 'analytics_active', path: 'assets/icons/navigation/analytics_active.svg' },
  { name: 'local_shipping_active', path: 'assets/icons/navigation/local_shipping_active.svg' },
  { name: 'local_shipping_outline', path: 'assets/icons/navigation/local_shipping_outline.svg' },
  { name: 'settings_black_24dp', path: 'assets/icons/admin-panel/settings_black_24dp.svg' },
  { name: 'business_black_24dp', path: 'assets/icons/admin-panel/business_black_24dp.svg' },
  { name: 'business_green_24dp', path: 'assets/icons/admin-panel/business_green_24dp.svg' },
  { name: 'add_business_black_24dp', path: 'assets/icons/admin-panel/add_business_black_24dp.svg' },
  { name: 'add_business_green_24dp', path: 'assets/icons/admin-panel/add_business_green_24dp.svg' },
  { name: 'schedule_black_24dp', path: 'assets/icons/admin-panel/schedule_black_24dp.svg' },
  { name: 'schedule_green_24dp', path: 'assets/icons/admin-panel/schedule_green_24dp.svg' },
  { name: 'shopping_cart_black_24dp', path: 'assets/icons/admin-panel/shopping_cart_black_24dp.svg' },
  { name: 'shopping_cart_green_24dp', path: 'assets/icons/admin-panel/shopping_cart_green_24dp.svg' },
  { name: 'drive_file_rename_outline_green_24dp', path: 'assets/icons/admin-panel/drive_file_rename_outline_green_24dp.svg' },
  { name: 'drive_file_rename_outline_black_24dp', path: 'assets/icons/admin-panel/drive_file_rename_outline_black_24dp.svg' },
  { name: 'local_shipping_black_24dp', path: 'assets/icons/admin-panel/local_shipping_black_24dp.svg' },
  { name: 'local_shipping_green_24dp', path: 'assets/icons/admin-panel/local_shipping_green_24dp.svg' },
  { name: 'contact_mail_green_24dp', path: 'assets/icons/admin-panel/contact_mail_green_24dp.svg' },
  { name: 'contact_mail_black_24dp', path: 'assets/icons/admin-panel/contact_mail_black_24dp.svg' },
  { name: 'contact_mail_black_24dp', path: 'assets/icons/admin-panel/contact_mail_black_24dp.svg' },
  { name: 'edit_black_24dp', path: 'assets/icons/admin-panel/edit_black_24dp.svg' },
  { name: 'task_black_fill_24dp', path: 'assets/icons/table-icons/task_black_fill_24dp.svg' },
  { name: 'thumb_down_black_fill_24dp', path: 'assets/icons/table-icons/thumb_down_black_fill_24dp.svg' },
  { name: 'thumb_up_black_fill_24dp', path: 'assets/icons/table-icons/thumb_up_black_fill_24dp.svg' },
  { name: 'service_not_possible', path: 'assets/icons/table-icons/service_not_possible.svg' },
  { name: 'swap_circle', path: 'assets/icons/table-icons/swap_circle.svg' },
  { name: 'eye_black_24dp', path: 'assets/icons/process-details/eye_black_24dp.svg' },
  { name: 'file_download_black_24dp', path: 'assets/icons/process-details/file_download_black_24dp.svg' },
  { name: 'picture_as_pdf_black_24dp', path: 'assets/icons/process-details/picture_as_pdf_black_24dp.svg' },
  { name: 'app_icon', path: 'assets/icons/app-icon/bbraun_resi_icon.svg' },
  { name: 'arrow_forward_grey', path: 'assets/icons/misc/arrow_forward_grey.svg' },
  { name: 'pdf_log', path: 'assets/icons/activity-log/pdf_log.svg' },
  { name: 'pickup_scheduled_log', path: 'assets/icons/activity-log/pickup_scheduled_log.svg' },
  { name: 'ready_for_shipment_log', path: 'assets/icons/activity-log/ready_for_shipment_log.svg' },
  { name: 'create_process_log', path: 'assets/icons/activity-log/create_process_log.svg' },
  { name: 'change_type_log', path: 'assets/icons/activity-log/change_type_log.svg' },
  { name: 'change_note_log', path: 'assets/icons/activity-log/change_note_log.svg' },
  { name: 'backorder_log', path: 'assets/icons/activity-log/backorder_log.svg' },
  { name: 'approval_required_log', path: 'assets/icons/activity-log/approval_required_log.svg' },
  { name: 'approved_log', path: 'assets/icons/activity-log/approved_log.svg' },
  { name: 'close_cancel_log', path: 'assets/icons/activity-log/close_cancel_log.svg' },
  { name: 'in_progress_log', path: 'assets/icons/activity-log/in_progress_log.svg' },
  { name: 'received_log', path: 'assets/icons/activity-log/received_log.svg' },
  { name: 'rejected_log', path: 'assets/icons/activity-log/rejected_log.svg' },
  { name: 'sent_to_customer_log', path: 'assets/icons/activity-log/sent_to_customer_log.svg' },
  { name: 'sent_to_service_log', path: 'assets/icons/activity-log/sent_to_service_log.svg' },
  { name: 'service_not_possible_log', path: 'assets/icons/activity-log/service_not_possible_log.svg' },
  { name: 'ready_for_delivery_log', path: 'assets/icons/activity-log/ready_for_delivery_log.svg' },
  { name: 'po_reference_log', path: 'assets/icons/activity-log/po_reference_log.svg' },
  { name: 'swap', path: 'assets/icons/navigation/swap.svg' },
  { name: 'swap_icon_in_button', path: 'assets/icons/misc/swap_icon_in_button.svg' },
  { name: 'swap_horiz', path: 'assets/icons/misc/swap_horiz.svg' },
  { name: 'high_priority', path: 'assets/icons/table-icons/high_priority.svg' },
  { name: 'delayed_icon', path: 'assets/icons/notifications/delayed_icon.svg' },
  { name: 'notification_new_rp_icon', path: 'assets/icons/notifications/notification_new_rp_icon.svg' },
  { name: 'notification_menu_opened', path: 'assets/icons/notifications/notification_menu_opened.svg' },
  { name: 'notification_menu_closed', path: 'assets/icons/notifications/notification_menu_closed.svg' },
  { name: 'no_results_icon', path: 'assets/icons/notifications/no_results_icon.svg' },
  { name: 'proforma-sidebar-button-icon', path: 'assets/icons/proforma-invoice/sidebar-button-icon.svg' }
];
