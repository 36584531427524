import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Event as RouterEvent, ResolveEnd, Router } from '@angular/router';

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs';

import { environment } from '../../../../environments/environment';

// Helper for the Azure Insights monitoring:
// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=angular
@Injectable({
  providedIn: 'root'
})
export class AzureInsightsHelperService {
  private angularPlugin: AngularPlugin = new AngularPlugin();
  private appInsights: ApplicationInsights | undefined;
  private clickPluginInstance = new ClickAnalyticsPlugin();
  private clickPluginConfig = {
    autoCapture : true,
    dataTags: {
      useDefaultContentNameOrId: true
    }
  };

  constructor(
    private readonly router: Router,
    private readonly destroyRef: DestroyRef) {
    this.trackRouterChanges();
  }

  // cast to any to bypass the type error
  // https://github.com/microsoft/applicationinsights-angularplugin-js/issues/97
  public handleMonitoring(): void {
    if (environment?.monitoring?.enable) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.monitoring.azureInsightsKey,
          extensions: [ this.angularPlugin as any, this.clickPluginInstance ],
          extensionConfig: {
            [this.angularPlugin.identifier]: { router: this.router },
            // Add the Click Analytics plug-in.
            [this.clickPluginInstance.identifier]: this.clickPluginConfig
          }
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  private activatedComponentName(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot?.firstChild) {
      return this.activatedComponentName(snapshot.firstChild);
    }
    return snapshot?.component?.name ?? '';
  }

  private trackPageView(pageView?: IPageViewTelemetry): void {
    if (environment?.monitoring?.enable && this.appInsights) {
      this.appInsights.trackPageView(pageView);
    }
  }

  private trackRouterChanges(): void {
    if (environment.monitoring?.enable) {
      this.router.events.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event: RouterEvent): event is ResolveEnd => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponentName = this.activatedComponentName(event.state.root);
          if (activatedComponentName) {
            this.trackPageView({ name: activatedComponentName, uri: event.urlAfterRedirects });
          }
        });
    }
  }
}

