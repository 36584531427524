import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { UiConfig, WelcomePageConfigWithKey } from '../../interfaces/layout/configs/ui-config';
import { User } from '../../interfaces/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  public getUser(): Observable<User> {
    return this.httpService.request('v1/user')
      .get(environment.backendUrls.CUSTOMER);
  }

  public getUiConfigs(userId: string, configurationKey?: string): Observable<{ payload: Array<UiConfig> }> {
    let request = this.httpService.request(`v1/user/${userId}/configurations`);
    if (configurationKey) {
      request = request.param('configurationKey', configurationKey);
    }

    return request.get(environment.backendUrls.CUSTOMER);
  }

  public updateUiConfig(userId: string, uiConfig: UiConfig): Observable<void> {
    return this.httpService.request(`v1/user/${userId}/configurations`)
      .param('configurationKey', uiConfig.configurationKey)
      .body(uiConfig.configuration)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getUiConfig(userId: string, configKey: string): Observable<{ payload: Array<WelcomePageConfigWithKey> }> {
    return this.httpService.request(`v1/user/${userId}/configurations`)
      .param('configurationKey', configKey)
      .get(environment.backendUrls.CUSTOMER);
  }
}
