<cx-navigation-drawer *transloco="let t">
  <div class="navigation-header">
    <cx-navigation-drawer-headline class="d-flex">
      @if (userConfigsFacade.isSuperAdminAsSignal()) {
        <app-navigation-country-switch/>
      }
      {{ userConfigsFacade.customerName$ | async }}
    </cx-navigation-drawer-headline>
    <button
      [routerLink]="ROUTES.CREATE_PROCESS"
      [queryParams]="{ tableName: processTablesNames.ORDER_LIST_EXTERNAL }"
      mat-flat-button
      color="accent"
      data-cy="create-process-navigation-btn">
      <mat-icon
        class="icon-in-button">
        add
      </mat-icon>
      {{ t('Create Process') }}
    </button>
  </div>
  <cx-navigation-drawer-button
    [name]="t('Overview')"
    [routerLink]="ROUTES.OVERVIEW"
    iconSvg="home_black_24dp"
    iconActive="home"
  />
  <cx-navigation-drawer-button
    [name]="t('All processes')"
    iconSvg="all_inbox_black_24dp"
    iconActive="all_inbox"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ tableName: processTablesNames.ALL_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Newly created')"
    iconSvg="ballot_outline_24dp"
    iconActiveSvg="ballot_active_24dp"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.NEW, tableName: processTablesNames.NEW_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Ready for shipment')"
    iconSvg="inventory_2_black_24dp"
    iconActive="inventory_2_black"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.READY_FOR_SHIPMENT, tableName: processTablesNames.READY_FOR_SHIPMENT_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Pickup scheduled')"
    iconSvg="schedule_send_black_24dp"
    iconActive="schedule_send_black"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.PICKUP_SCHEDULED, tableName: processTablesNames.PICKUP_SCHEDULED_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Sent to service')"
    iconSvg="send_black_24dp"
    iconActive="send_black"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.SENT, tableName: processTablesNames.SENT_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('In progress')"
    iconSvg="pending_black_24dp"
    iconActive="pending_black"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.IN_PROGRESS, tableName: processTablesNames.IN_PROGRESS_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Sent to us')"
    iconSvg="send_back_black_24dp"
    iconActiveSvg="send_black_active_24dp"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.INTERNAL_SENT, tableName: processTablesNames.INTERNAL_SENT_EXTERNAL }"
  />
  <cx-navigation-drawer-button
    [name]="t('Received')"
    icon="multiple_stop_black"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ status: processStatuses.RECEIVED, tableName: processTablesNames.RECEIVED_EXTERNAL }"
  />
    <cx-navigation-drawer-button
      [name]="t('Backorders')"
      icon="production_quantity_limits_black"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: processStatuses.BACKORDER, tableName: processTablesNames.BACKORDER_EXTERNAL }"
    />

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <cx-navigation-drawer-button
          [name]="t('Approvals')"
          iconSvg="task_black_24dp"
          iconActive="task_black"
        />
      </mat-expansion-panel-header>
      <cx-navigation-drawer-button
        [name]="t('Approval required')"
        [routerLink]="ROUTES.PROCESSES"
        [queryParams]="{ status: processStatuses.APPROVAL_REQUIRED, tableName: processTablesNames.APPROVAL_REQUIRED_EXTERNAL }"
        [badge]="layoutFacade.approvalsRequiredCountSignal()"
      />
      <cx-navigation-drawer-button
        [name]="t('Approved')"
        [routerLink]="ROUTES.PROCESSES"
        [queryParams]="{ status: processStatuses.APPROVED, tableName: processTablesNames.APPROVED_EXTERNAL }"
      />
      <cx-navigation-drawer-button
        [name]="t('Rejected')"
        [routerLink]="ROUTES.PROCESSES"
        [queryParams]="{ status: processStatuses.REJECTED, tableName: processTablesNames.REJECTED_EXTERNAL }"
      />
    </mat-expansion-panel>

  <mat-divider></mat-divider>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <cx-navigation-drawer-button
        [name]="t('Processes by type')"
        iconSvg="label_black_24dp"
        iconActive="label_black"
      />
    </mat-expansion-panel-header>
    <cx-navigation-drawer-button
      [name]="t('Repairs')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR, tableName: processTablesNames.REPAIR_EXTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Purchases')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.PURCHASE, tableName: processTablesNames.PURCHASE_EXTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Repair request')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR_REQUEST, tableName: processTablesNames.REPAIR_REQUEST_EXTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Repair replacements')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR_REPLACEMENT, tableName: processTablesNames.REPAIR_REPLACEMENT_EXTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Service not possible')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.SERVICE_NOT_POSSIBLE, tableName: processTablesNames.SERVICE_NOT_POSSIBLE_EXTERNAL }"
    />
  </mat-expansion-panel>

  <cx-navigation-drawer-button
    [name]="t('History')"
    [routerLink]="ROUTES.HISTORY"
    iconSvg="summarize_outline_24dp"
    iconActive="summarize_black"
    [queryParams]="{ tableName: processTablesNames.HISTORY_EXTERNAL }"
  />
  <div class="btn-overlay">
    <cx-navigation-drawer-button
      [name]="t('Analytics')"
      iconSvg="analytics_black_24dp"
      iconActiveSvg="analytics_active"
    />
  </div>
  <cx-navigation-drawer-button
    [name]="t('Help')"
    [routerLink]="ROUTES.HELP"
    [routerLinkActiveOptions]="{ exact: false }"
    icon="help_outline"
    iconActive="help"
  />
</cx-navigation-drawer>
