import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Injectable,
  PLATFORM_ID,
  inject,
  provideEnvironmentInitializer,
  EnvironmentProviders
} from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClarityHelperService {

  public static appendClarityScript(platformId: object, document: Document): void {
    if (isPlatformBrowser(platformId) &&
        environment?.clarity?.enable &&
        environment?.clarity?.projectId?.length > 0) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.innerHTML = ClarityHelperService.getClarityScriptContent(environment?.clarity?.projectId);
      document.head.appendChild(scriptElement);
    }
  }

  private static getClarityScriptContent(projectId: string): string {
    return `
     (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${ projectId }");
   `;
  }
}

export function provideClarityScript(): EnvironmentProviders {
  return provideEnvironmentInitializer(() => {
    const initializerFn = ((platformId: object, document: Document) => {
      return () => ClarityHelperService.appendClarityScript(platformId, document);
    })(inject(PLATFORM_ID), inject(DOCUMENT));
    return initializerFn();
  });
}
