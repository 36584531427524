import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { CxNavigationDrawerModule } from '@bbraun/cortex/navigation-drawer';
import { TranslocoDirective } from '@jsverse/transloco';
import { map } from 'rxjs';

import { ROUTES } from '../../shared/constants/routes.constants';
import { processTablesNames } from '../../shared/enums/process-tables/process-tables-names.enum';
import { ProcessStatuses } from '../../shared/enums/replenishment-process/process-status';
import { ProcessType } from '../../shared/enums/replenishment-process/process-type.enum';
import { LayoutStoreFacade } from '../../store/layout/layout-store.facade';
import { RouterStoreFacade } from '../../store/router/router-store.facade';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';
import { NavigationCountrySwitchComponent } from '../navigation-country-switch/navigation-country-switch.component';
import { CountrySwitchSidebarComponent } from '../sidebars/switch-country-sidebar/country-switch-sidebar.component';

@Component({
  selector: 'app-navigation-internal',
  standalone: true,
  imports: [
    CommonModule,
    CxNavigationDrawerModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    TranslocoDirective,
    RouterLink,
    NgOptimizedImage,
    NavigationCountrySwitchComponent,
    CountrySwitchSidebarComponent
  ],
  templateUrl: './navigation-internal.component.html',
  styleUrls: [ './navigation-internal.component.sass' ]
})
export class NavigationInternalComponent {

  protected readonly ROUTES = ROUTES;
  protected readonly ProcessType = ProcessType;
  protected readonly ProcessStatuses = ProcessStatuses;
  protected readonly processTablesNames = processTablesNames;

  protected readonly isStatusesPanelExpanded$ = this.routerFacade.url$
    .pipe(
      map(url => /\/processes\?.*status=[A-Z_]/.test(url))
    );

  constructor(
    public readonly userConfigsFacade: UserConfigsStoreFacade,
    public readonly routerFacade: RouterStoreFacade,
    public readonly layoutStoreFacade: LayoutStoreFacade
  ) { }
}
