import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, switchMap, withLatestFrom } from 'rxjs';

import { ReplenishmentService } from '../../pages/create-process-page/services/replenishment.service';
import { ProcessStatuses } from '../../shared/enums/replenishment-process/process-status';
import { ProcessTypeChangeReason } from '../../shared/enums/replenishment-process/process-type-change-reasons.enum';
import { ProcessType } from '../../shared/enums/replenishment-process/process-type.enum';
import { UiConfig } from '../../shared/interfaces/layout/configs/ui-config';
import { NotificationsService } from '../../shared/services/notifications/notifications.service';
import { UserService } from '../../shared/services/user/user.service';
import { CommonMethodsService } from '../../shared/services/utils/common-methods.service';
import * as LayoutActions from '../layout/layout.actions';
import { UserConfigsStoreFacade } from '../user-configs/user-configs-store.facade';
import * as UserConfigsActions from '../user-configs/user-configs.actions';

@Injectable()
export class LayoutEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly userConfigFacade: UserConfigsStoreFacade,
    private readonly userService: UserService,
    private readonly replenishmentService: ReplenishmentService,
    private readonly notificationsService: NotificationsService
  ) {}

  public loadApprovalsRequiredCount = createEffect(() => this.actions$.pipe(
    ofType(UserConfigsActions.userDataLoaded, LayoutActions.processCountsChange),
    switchMap(() => this.replenishmentService.getReplenishmentsCount(
      CommonMethodsService.prepareFiltersBase64String({ status: [ ProcessStatuses.APPROVAL_REQUIRED ]})
    ).pipe(
      map((approvalsRequiredCount: number) => LayoutActions.approvalsRequiredCountLoaded({ approvalsRequiredCount })),
      catchError(() => EMPTY))
    )));

  public loadNewPurchasesCount = createEffect(() => this.actions$.pipe(
    ofType(UserConfigsActions.userDataLoaded, LayoutActions.processCountsChange),
    switchMap(() => this.replenishmentService.getReplenishmentsCount(
      CommonMethodsService.prepareFiltersBase64String({ status: [ ProcessStatuses.NEW ], type: [ ProcessType.PURCHASE ]})
    ).pipe(
      map((newPurchasesCount: number) => LayoutActions.newPurchasesCountLoaded({ newPurchasesCount })),
      catchError(() => EMPTY))
    )));

  public loadUnreadNotificationsCount$ = createEffect(() => this.actions$.pipe(
    ofType(UserConfigsActions.userDataLoaded, LayoutActions.unreadNotificationsCount.load),
    switchMap(() => this.notificationsService.getUnreadNotificationsCount().pipe(
      map((count: number) => LayoutActions.unreadNotificationsCount.loadSuccessfully({ count }))
    ))
  ));

  public loadUiConfigs$ = createEffect(() => this.actions$.pipe(
    ofType(UserConfigsActions.userDataLoaded),
    withLatestFrom(this.userConfigFacade.userId$),
    switchMap(([ _, userId ]: [unknown, string | undefined ]) =>
      this.userService.getUiConfigs(userId!).pipe(
        map(({ payload }: { payload: Array<UiConfig> }) => LayoutActions.uiConfigsLoaded({ uiConfigs: payload }))
      ))
  ));

  public updateUiConfig$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.uiConfigChanged),
    withLatestFrom(
      this.userConfigFacade.userId$,
    ),
    switchMap(([{ uiConfig }, userId ]: [ { uiConfig: UiConfig }, string | undefined ]) =>
      this.userService.updateUiConfig(userId!, uiConfig).pipe(
        map(() => LayoutActions.uiConfigUpdated({ uiConfig }))
      )
    )
  ));

  public loadTypeChangeReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.changeTypeReasons.load),
      switchMap(() => this.replenishmentService.getChangeTypeReasons().pipe(
        map((reasons: Array<ProcessTypeChangeReason>) => LayoutActions.changeTypeReasons.loadSuccessfully({ reasons }))
      ))
    );
  });
}
