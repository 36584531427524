<cx-navigation-drawer *transloco="let t">
  <div class="navigation-header">
    <cx-navigation-drawer-headline class="d-flex">
      @if (userConfigsFacade.isSuperAdminAsSignal()) {
        <app-navigation-country-switch/>
      }
      {{ t('B. Braun Technical Service') }}
    </cx-navigation-drawer-headline>
  </div>
  <cx-navigation-drawer-button
    [name]="t('Overview')"
    iconSvg="home_black_24dp"
    iconActive="home"
    [routerLink]="ROUTES.OVERVIEW"
  />
  <cx-navigation-drawer-button
    [name]="t('All processes')"
    iconSvg="all_inbox_black_24dp"
    iconActive="all_inbox"
    [routerLink]="ROUTES.PROCESSES"
    [queryParams]="{ tableName: processTablesNames.ALL_INTERNAL }"
  />
  <mat-expansion-panel [expanded]="(isStatusesPanelExpanded$ | async)!">
    <mat-expansion-panel-header>
      <cx-navigation-drawer-button
        [name]="t('Statuses')"
        iconSvg="local_offer_black_24dp"
      />
    </mat-expansion-panel-header>
    <cx-navigation-drawer-button
      [name]="t('Newly created')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.NEW, tableName: processTablesNames.NEW_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Ready for shipment')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.READY_FOR_SHIPMENT, tableName: processTablesNames.READY_FOR_SHIPMENT_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Pickup scheduled')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.PICKUP_SCHEDULED, tableName: processTablesNames.PICKUP_SCHEDULED_INTERNAL }"
    />

    <cx-navigation-drawer-button
      [name]="t('Sent to us')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.SENT, tableName: processTablesNames.SENT_INTERNAL }"
    />

    <cx-navigation-drawer-button
      [name]="t('In progress')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.IN_PROGRESS, tableName: processTablesNames.IN_PROGRESS_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('In Workshop')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.IN_WORKSHOP, tableName: processTablesNames.IN_WORKSHOP_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Approval required')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.APPROVAL_REQUIRED, tableName: processTablesNames.APPROVAL_REQUIRED_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Rejected')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.REJECTED, tableName: processTablesNames.REJECTED_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Approved')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.APPROVED, tableName: processTablesNames.APPROVED_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Backorder')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.BACKORDER, tableName: processTablesNames.BACKORDER_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Ready for delivery')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.READY_FOR_DELIVERY, tableName: processTablesNames.READY_FOR_DELIVERY_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Sent to customer')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.INTERNAL_SENT, tableName: processTablesNames.INTERNAL_SENT_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Received')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ status: ProcessStatuses.RECEIVED, tableName: processTablesNames.RECEIVED_INTERNAL }"
    />
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <cx-navigation-drawer-button
        [name]="t('Processes by type')"
        iconSvg="label_black_24dp"
      />
    </mat-expansion-panel-header>
    <cx-navigation-drawer-button
      [name]="t('Repairs')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR, tableName: processTablesNames.REPAIR_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Purchases')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.PURCHASE, tableName: processTablesNames.PURCHASE_INTERNAL }"
      [badge]="layoutStoreFacade.newPurchasesCountSignal()"
    />
    <cx-navigation-drawer-button
      [name]="t('Repair request')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR_REQUEST, tableName: processTablesNames.REPAIR_REQUEST_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Repair replacements')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.REPAIR_REPLACEMENT, tableName: processTablesNames.REPAIR_REPLACEMENT_INTERNAL }"
    />
    <cx-navigation-drawer-button
      [name]="t('Service not possible')"
      [routerLink]="ROUTES.PROCESSES"
      [queryParams]="{ type: ProcessType.SERVICE_NOT_POSSIBLE, tableName: processTablesNames.SERVICE_NOT_POSSIBLE_INTERNAL }"
    />
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <cx-navigation-drawer-button
    [name]="t('Notifications')"
    [routerLink]="ROUTES.NOTIFICATIONS"
    icon="notifications_none"
    iconActive="notifications"
    [badge]="layoutStoreFacade.unreadNotificationsCountSignal()"
  />
  <cx-navigation-drawer-button
    [name]="t('History')"
    [routerLink]="ROUTES.HISTORY"
    iconSvg="summarize_outline_24dp"
    iconActive="summarize_black"
    [queryParams]="{ tableName: processTablesNames.HISTORY_INTERNAL }"
  />
  <div class="btn-overlay">
    <cx-navigation-drawer-button
      [name]="t('Analytics')"
      iconSvg="analytics_black_24dp"
      iconActiveSvg="analytics_active"
    />
  </div>
  <cx-navigation-drawer-button
    [name]="t('Help')"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="ROUTES.HELP"
    icon="help_outline"
    iconActive="help"
  />
  @if (userConfigsFacade.isAdminAsSignal()) {
    <mat-divider/>
    <cx-navigation-drawer-button
      [name]="t('Admin panel')"
      [routerLink]="ROUTES.ADMIN_PANEL"
      iconActiveSvg="settings_black_24dp"
      icon="settings"
    />
  }
</cx-navigation-drawer>
