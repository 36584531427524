import { Route } from '@angular/router';

import { SilentRefreshComponent } from './auth/silent-refresh/silent-refresh.component';
import { ROUTE_DATA_CONSTANTS, ROUTES } from './shared/constants/routes.constants';
import { UserRolesEnum } from './shared/enums/user-roles/user-roles.enum';
import { canAccessRoute } from './shared/guards/user-rights-guard';
import { welcomePageGuard } from './shared/guards/welcome-page.guard';

export const appRoutes: Array<Route> = [
  {
    path: ROUTES.WELCOME,
    canActivate: [ welcomePageGuard ],
    loadChildren: () => import('./pages/welcome-page/welcome-page.routes')
      .then(mod => mod.welcomePageRoutes)
  },
  {
    path: ROUTES.COUNTRY_SETUP,
    canActivate: [ canAccessRoute ],
    data: {
      [ROUTE_DATA_CONSTANTS.FALLBACK_ROUTE]: '/',
      [ROUTE_DATA_CONSTANTS.REQUIRED_RIGHTS]: [ UserRolesEnum.SUPER_ADMIN ]
    },
    loadChildren: () => import('./pages/country-setup-page/country-setup-page.routes')
      .then(mod => mod.countrySetupPageRoutes)
  },
  {
    path: ROUTES.CREATE_PROCESS,
    loadChildren: () => import('./pages/create-process-page/create-process-page.routes')
      .then(mod => mod.createProcessPageRoutes)
  },
  {
    path: ROUTES.PROCESSES,
    loadChildren: () => import('./pages/all-processes-page/all-processes-page.routes')
      .then(mod => mod.allProcessesPageRoutes)
  },
  {
    path: ROUTES.HISTORY,
    loadChildren: () => import('./pages/history-page/history-page.routes')
      .then(mod => mod.historyPageRoutes)
  },
  {
    path: ROUTES.OVERVIEW,
    loadChildren: () => import('./pages/overview-page/overview-page.routes')
      .then(mod => mod.overviewPageRoutes),
  },
  {
    path: ROUTES.ADMIN_PANEL,
    canActivate: [ canAccessRoute ],
    data: {
      [ROUTE_DATA_CONSTANTS.FALLBACK_ROUTE]: '/',
      [ROUTE_DATA_CONSTANTS.ONE_NEEDED_RIGHT]: [ UserRolesEnum.SUPER_ADMIN, UserRolesEnum.COUNTRY_ADMIN ]
    },
    loadChildren: () => import('./pages/admin-panel-page/admin-panel-page.routes')
      .then(mod => mod.adminPageRoutes)
  },
  {
    path: ROUTES.HELP,
    loadChildren: () => import('./pages/help-page/help-page.routes')
      .then(mod => mod.helpPageRoutes)
  },
  {
    path: ROUTES.NOTIFICATIONS,
    loadChildren: () => import('./pages/notifications-page/notifications-page.routes')
      .then(mod => mod.notificationsPageRoutes)
  },
  {
    path: ROUTES.UNKNOWN,
    redirectTo: ROUTES.WELCOME
  },
  {
    path: ROUTES.SILENT_REFRESH,
    component: SilentRefreshComponent
  }
];
