import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
const CxMaterialConfigProviders = [{
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: {
    appearance: 'outline',
    floatLabel: 'always'
  }
}, {
  provide: MAT_SELECT_CONFIG,
  useValue: {
    disableOptionCentering: true,
    overlayPanelClass: 'cx-select-overlay'
  }
}, {
  provide: MAT_RADIO_DEFAULT_OPTIONS,
  useValue: {
    color: 'primary'
  }
}, {
  provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
  useValue: {
    color: 'primary'
  }
}, {
  provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
  useValue: {
    color: 'primary'
  }
}, {
  provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  useValue: {
    autoActiveFirstOption: false
  }
}, {
  provide: MAT_RIPPLE_GLOBAL_OPTIONS,
  useValue: {
    disabled: false,
    animation: {
      enterDuration: 300,
      exitDuration: 0
    }
  }
}];

/**
 * Generated bundle index. Do not edit.
 */

export { CxMaterialConfigProviders };
