
import { Component, Input } from '@angular/core';

import { CxImageModule } from '@bbraun/cortex/image';
import { CxMessageModule } from '@bbraun/cortex/message';
import { TranslocoDirective } from '@jsverse/transloco';

import { NoAccessReasons } from '../../shared/enums/user-roles/no-access-reasons.enum';

@Component({
  selector: 'app-no-permission',
  standalone: true,
  imports: [
    CxMessageModule,
    TranslocoDirective,
    CxImageModule
  ],
  templateUrl: './no-permission.component.html',
  styleUrls: [ './no-permission.component.sass' ]
})
export class NoPermissionComponent {
  @Input({ required: true }) public reason: NoAccessReasons = NoAccessReasons.MISSING_PERMISSIONS;
  protected readonly NoAccessReasons = NoAccessReasons;
}
