import { ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

import { CxHeartBeatModule, CxMenuModule } from '@bbraun/cortex/carousel';
import { TranslocoDirective } from '@jsverse/transloco';

import { ROUTES } from '../../shared/constants/routes.constants';
import { NotificationsStoreService } from '../../shared/signal-stores/notifications-store/notifications-store.service';
import { LayoutStoreFacade } from '../../store/layout/layout-store.facade';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';
import { NotificationRowComponent } from '../notifications/notification-row/notification-row.component';

@Component({
  selector: 'app-header-notifications',
  imports: [
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    CxMenuModule,
    MatDivider,
    TranslocoDirective,
    CxHeartBeatModule,
    NotificationRowComponent
  ],
  templateUrl: './header-notifications.component.html',
  styleUrl: './header-notifications.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNotificationsComponent {
  public isNotificationDropdownOpen = signal<boolean>(false);

  public readonly notificationsState = inject(NotificationsStoreService);
  public readonly userConfigsFacade = inject(UserConfigsStoreFacade);
  public readonly layoutFacade = inject(LayoutStoreFacade);

  private readonly notificationMenuTrigger = viewChild<MatMenuTrigger>('notificationMenuTrigger');
  private readonly router = inject(Router);

  public onMenuChange(isMenuOpened: boolean): void {
    this.isNotificationDropdownOpen.set(isMenuOpened);
    if (isMenuOpened) {
      this.notificationsState.loadNotifications();
    }
  }

  public onMarkAllAsReadClicked(): void {
    this.notificationsState.markAllAsRead();
  }

  public onViewAllNotificationsClick(): void {
    if (this.notificationMenuTrigger()) {
      this.notificationMenuTrigger()!.closeMenu();
    }
    this.router.navigate([ ROUTES.NOTIFICATIONS ]);
  }
}
