import { NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslocoDirective } from '@jsverse/transloco';

import { SidebarDisplayStore } from '../../shared/signal-stores/sidebar-display-store/sidebar-display.store';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-navigation-country-switch',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatIconModule,
    MatButtonModule,
    TranslocoDirective,
    MatTooltipModule
  ],
  templateUrl: './navigation-country-switch.component.html',
  styleUrl: './navigation-country-switch.component.sass'
})
export class NavigationCountrySwitchComponent {

  public userConfigsFacade = inject(UserConfigsStoreFacade);
  public sidebarDisplayStore = inject(SidebarDisplayStore);

  public onSwitchClicked(): void {
    this.userConfigsFacade.isInternalAsSignal()
      ? this.sidebarDisplayStore.showCountrySwitchSidebar()
      : this.sidebarDisplayStore.showSwitchViewSidebar();
  }
}
