import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable, of, take } from 'rxjs';

import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';
import { ROUTES } from '../constants/routes.constants';
import { uiConfigKeys } from '../constants/ui-configs/ui-config-keys';
import { WelcomePageConfigWithKey } from '../interfaces/layout/configs/ui-config';
import { UserService } from '../services/user/user.service';

export const welcomePageGuard = (): Observable<boolean> => {
  const userService = inject(UserService);
  const userConfigsStoreFacade = inject(UserConfigsStoreFacade);
  const router = inject(Router);

  if (userConfigsStoreFacade.userIdAsSignal()) {
    return userService.getUiConfig(userConfigsStoreFacade.userIdAsSignal()!, uiConfigKeys.WELCOME_PAGE).pipe(
      take(1),
      map((result: { payload: Array<WelcomePageConfigWithKey> }) => {
        if (result.payload.length > 0 && !result.payload[0].configuration.showWelcomePage) {
          router.navigate([ ROUTES.OVERVIEW ]);
          return false;
        }
        return true;
      })
    );
  }

  return of(false);
};
